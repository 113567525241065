<template>
  <Cluster v-bind="$props" @click="() => $emit('click')">
    <slot />
  </Cluster>
</template>

<script>
import Cluster from 'vue2-google-maps/dist/components/cluster';
export default {
  components: {
    Cluster
  },
  props: {
    gridSize: { type: Number, default: 20 },
    minimumClusterSize: { type: Number, default: 3 },
    imagePath: { type: String, default: '/img/cluster/' },
    zoomOnClick: { type: Boolean, default: true },
    clickable: { type: Boolean, default: true }
  }
};
</script>
